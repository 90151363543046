'use client';
import styles from './styles.module.scss';
import Typography from '@/components/shared/typography/typography.component';
import { ReactNode } from 'react';

import { useTranslation } from '@/hook';

const BodyTypesSection = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation();

    return (
        <section className={styles.section_container}>
            <Typography variant="h5Bold" className={'text-primary-600'}>
                {t('Popular Body Styles')}
            </Typography>

            <div className={styles.cards_container}>
                <>{children}</>
            </div>
        </section>
    );
};
export default BodyTypesSection;
