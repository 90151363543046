'use client';
import styles from './styles.module.scss';

import { useTranslation } from '@/hook';
import Typography from '@/components/shared/typography/typography.component';
import CarDisplayCardClient from '@/components/shared/car-details-card/car-details-car-client.component';
import Link from '@/components/WrappedLink';
import { CarListingData } from '@/types';
import { AVAILABLE_LANGUAGES } from '@/constants';
import { TFebDiscounts } from '@/api-requests/feb-discounts.apis';
import { getCarCardLocale } from '@/utils';

export default function SylndrCars({
    lang,
    recentlyAddedData,
    febDiscounts,
}: {
    lang: AVAILABLE_LANGUAGES;
    recentlyAddedData: CarListingData[];
    febDiscounts: TFebDiscounts;
}) {
    const { t } = useTranslation();
    const carCardLocalizations = getCarCardLocale();

    return (
        <section className={styles.container}>
            <div className={styles.headerContainer}>
                <div className={styles.headerDescriptionContainer}>
                    <Typography className={styles.header} variant="h5Bold">
                        {t('Recently added')}
                    </Typography>

                    <Typography
                        className={styles.subHeader}
                        variant="subtitle1"
                    >
                        {t('Sylndr certified cars')}
                    </Typography>
                </div>
                <div className={styles.desktopActionButton}>
                    <Link
                        style={{
                            width: '200px',
                            height: '48px',
                            display: 'flex',
                        }}
                        className="sylndr_button primary"
                        href={`/${lang}/buy-used-cars`}
                    >
                        <Typography
                            variant="body1Medium"
                            style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingRight: '20px',
                                paddingLeft: '20px',
                                width: 'fit-content',
                            }}
                        >
                            {' '}
                            {t('browse all cars')}
                        </Typography>
                    </Link>
                </div>
            </div>
            <div
                className={
                    'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-y-4 gap-x-2 w-full'
                }
            >
                {recentlyAddedData.map((car, index) => {
                    return (
                        <div
                            className={'flex-1'}
                            key={`car-${car.id}-${car.status}-${car.updatedAt}`}
                        >
                            <CarDisplayCardClient
                                febDiscount={febDiscounts[car.id] || 0}
                                carCardLocalizations={carCardLocalizations}
                                lang={lang}
                                id={car.id}
                                cardTitle={`${t(car.make)} ${car.model} ${
                                    car.year
                                }`}
                                carName={car.sfVehicleName}
                                trim={car.trim}
                                installmentPrice={car?.installmentPrice}
                                totalPrice={car.sylndrSellingPrice}
                                kilometers={car.kilometers}
                                year={car.year}
                                transmission={car.transmission}
                                images={
                                    car?.images.length !== 0 ? car?.images : []
                                }
                                isReserved={car.isReserved}
                                make={car.make}
                                model={car.model}
                                sourceScreen={'Home Page'}
                                appliedFilter={false}
                                appliedSort={false}
                                position={index + 1}
                                isPurchaseInProgress={car.isPurchaseInProgress}
                                discountPrice={car.discountPrice}
                                isNewlyAdded={car.isNewlyAdded}
                            />
                        </div>
                    );
                })}
            </div>
        </section>
    );
}
