'use client';
import TitleImageCard from '@/components/shared/image-title-card/image-title-card.component';
import Link from '@/components/WrappedLink';
import { useTranslation } from '@/hook';
import Image from 'next/image';
import { IPopularBrnadsResult } from '@/types';
import { slugify } from '@/utils/filterUtil';

const PopularBrandsData = ({
    lang,
    brandData,
}: {
    lang: 'ar' | 'en';
    brandData: IPopularBrnadsResult;
}) => {
    const { t } = useTranslation();

    const textForImageUrl = (text: string) => {
        if (text === 'Mercedesbenz')
            return 'https://sylndr-website.s3.us-east-2.amazonaws.com/Car+Make+Logos/Mercedes.jpg';
        if (text === 'MINI')
            return 'https://sylndr-website.s3.us-east-2.amazonaws.com/Car+Make+Logos/Mini+Cooper.jpg';
        return `https://sylndr-website.s3.us-east-2.amazonaws.com/Car+Make+Logos/${text}.jpg`;
    };
    const returnMake = (make: string) => {
        if (make === 'Mercedesbenz') return 'Mercedes';
        return make;
    };
    return (
        <>
            {brandData?.data &&
                brandData.data.slice(0, 12).map(item => (
                    <Link
                        style={{ textDecoration: 'none' }}
                        key={item.make}
                        prefetch={false}
                        href={`/${lang}/buy-used-cars/${slugify(item.make)}`}
                    >
                        <TitleImageCard
                            cardData={{
                                title: returnMake(t(item.make) as string),
                                titleColor: 'black',
                                fontSize: '14px',
                                img: (
                                    <Image
                                        width={100}
                                        height={100}
                                        style={{
                                            width: '60%',
                                            objectFit: 'contain',
                                            height: 'max-content',
                                        }}
                                        src={textForImageUrl(item.make)}
                                        alt={item.make}
                                    />
                                ),
                            }}
                        />
                    </Link>
                ))}
        </>
    );
};
export default PopularBrandsData;
