'use client';
import styles from './styles.module.scss';
import Typography from '@/components/shared/typography/typography.component';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { ChevronRight } from '@mui/icons-material';

import { useTranslation, useWindowDimensions } from '@/hook';
const PopularBrandsSection = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation();
    const contentConatainerRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState(false);
    const cardContainerRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowDimensions();
    const adjustCOntainerHieght = () => {
        if (!contentConatainerRef?.current || !cardContainerRef?.current)
            return;
        // const card = cardContainerRef.current.children[0];
        // const cardHeight = card.clientHeight;
        const cardHeightLg = 162;
        const cardHeightMd = 129;
        const cardHeightSm = 100;
        const MARGIN_DOWN_OPEN = 42;
        if (width > 1000)
            contentConatainerRef.current.style.height = `${
                cardHeightLg + MARGIN_DOWN_OPEN
            }px`;
        if (width <= 1000 && width > 460)
            contentConatainerRef.current.style.height = `${
                cardHeightMd + MARGIN_DOWN_OPEN
            }px`;
        if (width <= 460)
            contentConatainerRef.current.style.height = `${
                cardHeightSm + MARGIN_DOWN_OPEN
            }px`;
    };

    useEffect(() => {
        adjustCOntainerHieght();
    }, [width]);
    const viewMoreClicled = () => {
        if (!contentConatainerRef?.current || !cardContainerRef?.current)
            return;
        const { height } = cardContainerRef.current.getBoundingClientRect();
        const card = cardContainerRef.current.children[0];
        const cardHeight = card.clientHeight;
        const MARGIN_DOWN_CLOSED = 32;
        const MARGIN_DOWN_OPEN = 42;
        if (!expanded) {
            contentConatainerRef.current.style.height = `${
                height + MARGIN_DOWN_CLOSED
            }px`;
            setExpanded(true);
            return;
        }
        if (expanded) {
            contentConatainerRef.current.style.height = `${
                cardHeight + MARGIN_DOWN_OPEN
            }px`;
            setExpanded(false);
        }
    };
    return (
        <section className={styles.section_container}>
            <Typography color={'#009B5D'} variant="h5Bold">
                {t('Popular Brands')}
            </Typography>

            <div
                className={styles.content_container}
                ref={contentConatainerRef}
            >
                <div className={styles.cards_container} ref={cardContainerRef}>
                    <>{children}</>
                </div>
                <div className={styles.view_more_container}>
                    <div
                        className={styles.view_more_content_container}
                        onClick={() => viewMoreClicled()}
                    >
                        <Typography color={'#00673E'} variant="body1Medium">
                            {expanded
                                ? t('View Less Brands')
                                : t('View More Brands')}
                        </Typography>
                        <ChevronRight
                            style={{
                                color: '#00673E',
                                transform: expanded
                                    ? 'rotate(270deg)'
                                    : 'rotate(90deg)',
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default PopularBrandsSection;
