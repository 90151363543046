export { default as Certificate } from './certificate.svg';
export { default as InspectionPoints } from './inspectionPoints.svg';
export { default as MoneyBackBadge } from './MoneyBackBadge.svg';
export { default as WrrantyBadge } from './warrantyBadge.svg';

export { default as AssuranceIcon } from './assuranceIcon.svg';
export { default as CarIcon } from './carIcon.svg';
export { default as MoneyIcon } from './moneyIcon.svg';
export { default as SylndrLogo } from './sylndrLogo.svg';
export { default as TruckIcon } from './truckIcon.svg';
export { default as WideAngleIcon } from './wideAngleIcon.svg';

export { default as minivan } from './bodyTypes/minivan.svg';
export { default as coupe } from './bodyTypes/coupe.svg';
export { default as sedan } from './bodyTypes/sedan.svg';
export { default as hatchback } from './bodyTypes/hatchback.svg';
export { default as crossover } from './bodyTypes/crossover.svg';
export { default as suv } from './bodyTypes/suv.svg';
