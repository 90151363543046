'use client';
import styles from './styles.module.scss';

import TitleImageCard from '@/components/shared/image-title-card/image-title-card.component';

import Link from '@/components/WrappedLink';

import {
    coupe,
    crossover,
    hatchback,
    minivan,
    sedan,
    suv,
} from '../../../../public/Images/HomePage/index';
import { useTranslation } from '@/hook';
import { IBodyTypeResult } from '@/types';
import { possibleBuyUseCarsSources } from '@/types/filter.types';
import { slugify } from '@/utils/filterUtil';
import { ACTION_CLICK_BUY_CAR } from '@/constants/actions';
import { useAnalytics } from '@/integrations';

const BodyTypesData = ({
    lang,
    bodyData,
}: {
    lang: 'ar' | 'en';
    bodyData: IBodyTypeResult;
}) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();

    const bodyStylesImages = {
        minivan: minivan,
        coupe: coupe,
        sedan: sedan,
        hatchback: hatchback,
        crossover: crossover,
        suv: suv,
        '4x4': suv,
    };
    const textForImageUrl = (text: string) => {
        if (!bodyStylesImages[text as keyof typeof bodyStylesImages]) return;

        return bodyStylesImages[text as keyof typeof bodyStylesImages].src;
    };

    return (
        <>
            {bodyData?.data &&
                bodyData.data.map(item => (
                    <Link
                        style={{
                            textDecoration: 'none',
                        }}
                        key={item.body_style}
                        onClick={() => {
                            trackEvent(ACTION_CLICK_BUY_CAR, {
                                source: possibleBuyUseCarsSources.homepage_body_types,
                            });
                        }}
                        href={`/${lang}/buy-used-cars?bodystyle=${slugify(
                            item.body_style
                        )}`}
                    >
                        <div className={styles.card_container}>
                            <TitleImageCard
                                cardData={{
                                    /*
@eslint-disable-next-line @typescript-eslint/ban-ts-comment
@ts-ignore */
                                    title: t(item.body_style) as string,
                                    titleColor: '#344054',
                                    fontSize: '16px',
                                    img: (
                                        <div
                                            style={{
                                                width: '88px',
                                                height: '88px',
                                                display: 'grid',
                                                placeContent: 'center',
                                            }}
                                        >
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img
                                                style={{
                                                    maxWidth: '86px',
                                                    objectFit: 'cover',
                                                }}
                                                src={textForImageUrl(
                                                    item.body_style
                                                )}
                                                alt={item.body_style}
                                            />
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                    </Link>
                ))}
        </>
    );
};
export default BodyTypesData;
