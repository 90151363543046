'use client';
import Typography from '@/components/shared/typography/typography.component';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface IImageTitleCard {
  title: string;
  titleColor: string;
  img: ReactNode;
  fontSize?: string;
}

const TitleImageCard = ({ cardData }: { cardData: IImageTitleCard }) => {
  const { title, titleColor, img, fontSize } = cardData;

  return (
    <div className={styles.card_container}>
      {img}
      <Typography style={{ fontSize: fontSize }} color={titleColor} variant="subtitle2Bold">
        {title}
      </Typography>
    </div>
  );
};
export default TitleImageCard;
