'use client';

import { useTranslation } from '@/hook';

import { ArrowLeft, CantFindCar } from '../../../public/Images';
import styles from './styles.module.scss';
import Typography from '@/components/shared/typography/typography.component';
import Link from '@/components/WrappedLink';
import { possibleBuyUseCarsSources } from '@/types/filter.types';
import { ACTION_CLICK_BUY_CAR } from '@/constants/actions';
import { useAnalytics } from '@/integrations';

export const NotFoundPage = () => {
    const { t, i18n, IS_RTL } = useTranslation();
    const { trackEvent } = useAnalytics();

    return (
        <div
            className={styles.notFoundPageContainer}
            style={{ direction: IS_RTL ? 'rtl' : 'ltr' }}
        >
            <img
                src={CantFindCar.src}
                style={{ width: '100%' }}
                alt="CantFindCar"
                className={styles.image}
            />

            <div className={styles.textContainer}>
                <Typography
                    variant="body1Medium"
                    style={{
                        marginTop: '24px',
                        color: '#00673E',
                        marginLeft: '4px',
                    }}
                >
                    {t('404 error')}
                </Typography>

                <Typography
                    style={{
                        marginTop: '12px',
                    }}
                    className={styles.noPageFoundLabel}
                >
                    {t('No Page found')}
                </Typography>

                <div className={styles.buttonsContainer}>
                    <Link
                        onClick={() => {
                            trackEvent(ACTION_CLICK_BUY_CAR, {
                                source: possibleBuyUseCarsSources.not_found_page,
                            });
                        }}
                        href={`/${i18n.language}/buy-used-cars`}
                        className={styles.browseAllCarsButton}
                    >
                        <Typography variant="body1Medium">
                            {t('browse all cars')}
                        </Typography>
                    </Link>

                    <Link
                        href={`/${i18n.language}`}
                        className={styles.homeButton}
                    >
                        <img
                            src={ArrowLeft.src}
                            alt="ArrowLeft"
                            style={{
                                transform: IS_RTL ? 'scaleX(-1)' : 'scaleX(1)',
                            }}
                        />
                        <Typography variant="body1Medium">
                            {t('home')}
                        </Typography>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
